import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`1) Trail character`}</h4>
    <p>{`At the beginning, is recommended to describe the character of the activity
or the experience in one comprehensive sentence. "Intense descend in rocky
and technical terrain." or "Easy natural trail with a stunning view into
the XY valley."`}</p>
    <br /><br />
    <h4>{`2) Detailed description`}</h4>
    <p>{`More detailed, but still compact and limited to important information.
Get a few ideas what you could write about:`}</p>
    <br />
    <table className="text-sm shadow" cellSpacing="0">
  <tbody>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Surface</b></td>
      <td className="px-4 py-2">Roots, rock, stone, gravel, sand, gras, dirt, needles, leafs, loose, compact</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Obstacles</b></td>
      <td className="px-4 py-2">Roots, rocks, stones, steps, off camber</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Built features</b></td>
      <td className="px-4 py-2">Berms, doubles, tables, drops, step up, step down, north shore, bridges</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Riding</b></td>
      <td className="px-4 py-2"> Flow, speed, blocked, smooth, rough, climbs, pedal sections, push biking</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Grade</b></td>
      <td className="px-4 py-2">Steep, flat, up and down</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Interests</b></td>
      <td className="px-4 py-2">View, scenery, points of interest, historical or geographical background</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Environment</b></td>
      <td className="px-4 py-2">forest, meadow, rock, alpine, river, valley, ridge, traverse, exposition, animals</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Supply</b></td>
      <td className="px-4 py-2">Water sources, shelters, huts, restaurants on the way</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Hazards</b></td>
      <td className="px-4 py-2">Exposed trail, steep slope, cliff, tree, landslide, gate, closing corner, junction, low branches, narrow trees, long section without shadow and heat during summer</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Safety</b></td>
      <td className="px-4 py-2">Network coverage, contact of local/ regional rescue services, rescue locations (access points)</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Season</b></td>
      <td className="px-4 py-2">Time of the year when to ride best</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Weather</b></td>
      <td className="px-4 py-2">Can the trail be ridden in wet conditions? Any dangers with bad weather (rivers rising, rocks falling, ...)</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Impression</b></td>
      <td className="px-4 py-2">Feelings, emotions, surprises, disappointments, ...</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Sections</b></td>
      <td className="px-4 py-2">You can divide your description into upper, middle, lower part, or others.</td>
    </tr>
    <tr className="bg-gray-200">
      <td className="px-4 py-2 text-right"><b>Routing</b></td>
      <td className="px-4 py-2">Avoid to describe the route. That's where the gps track and the location function is for. Only mention tips, when it easy to make mistakes e.g. when the trail is close and parallel to a fireroad, and it's hard to distinguish it on the map.</td>
    </tr>
    <tr>
      <td className="px-4 py-2 text-right"><b>Length, elevation</b></td>
      <td className="px-4 py-2">This information is automatically generated from the GPS data. No need to mention this.</td>
    </tr>
  </tbody>
    </table>
    <br /><br />
    <h4>{`3) Difficulty`}</h4>
    <p>{`The difficulty (green, blue, red, black) is only based on the technical demands
of the trail! The physical demands have to be considered because of the length,
elevation and technical difficulty of the activity. The length and elevation
are automatically extracted from the gps track, so no extra rating for the
physical difficulty is necessary! Two examples: There can be a very long tour
with lots of climbs, that is technically easy = green. There can be a very
short trail, that is technically very challenging = black.`}</p>
    <br />
    <p>{`The difficulty is the average difficulty of the trail. A green trail with
one difficult section is still green. 5m of difficult trail don’t affect the
experience of the biker on this trail. An advantage of mountain biking is,
you can stop, and walk down sections that are too difficult. Following you
find the definition of the Trailguide difficulty rating system. It is very
visual, and even without explanation it is understandable that green is easy,
and black is hard:`}</p>
    <br /><br />
    <table className="text-sm shadow text-white" cellSpacing="0">
  <tbody>
    <tr className="bg-green-600">
      <td className="p-4"><b>Green</b></td>
      <td className="p-4">
        A singletrack that has no particular difficulties. Trail is also
        suitable for beginners. The surface is mostly compact and grippy.
        Obstacles that stand out of the surface like rocks, roots, or steps
        are not to be expected. You don't have to brake much, the grade is flat
        or slightly descending, the corners are wide and easy.
      </td>
    </tr>
    <tr className="bg-blue-600">
      <td className="p-4"><b>Blue</b></td>
      <td className="p-4">
        Trails require basic biking technique like controlled braking and
        shifting your bodyweight. It still can be managed by sportive
        beginners. Here you have to expect small obstacles like roots,
        rocks, or steps. All obstacles can be rolled. The surface can be
        less compact with loose gravel, little rocks, or sand. The grade
        can be steeper, and requires more precise braking. There can be
        built obstacles like jumps that are all rollable, like tables or
        drops with ramps.
      </td>
    </tr>
    <tr className="bg-red-600">
      <td className="p-4"><b>Red</b></td>
      <td className="p-4">
        Trails require good biking technique. Beginners will have less fun on
        this trail, and probably push the bike more often. Precise braking,
        active body position, and dynamic shifting of bodyweight, good balance,
        looking ahead while riding, good cornering technique is required. The
        obstacles can be bigger and off-camber. There can be tight corners,
        sometimes even narrow switchbacks. The surface can be loose over
        longer sections. The grade can be steeper, there also can be longer
        continuous steep sections, or obstacles in the steep sections.
        Obstacles can occur that you can’t roll, but have to jump over or
        dynamically lift your bike over.
      </td>
    </tr>
    <tr className="bg-gray-900">
      <td className="p-4"><b>Black</b></td>
      <td className="p-4">
        Very good biking technique is required. Beginners will push down most
        of the trail. Intermediate riders will have less fun on this trail,
        and probably push several times. The obstacles can be high, and occur
        in a higher density, over longer sections. Also steep sections have
        obstacles like steps, channels, off-camber. Tight switchbacks can
        make turning over the front wheel necessary. There can be sections
        that are so steep that it is not possible to stop everywhere. There
        can be non rollable obstacles like bigger steps. Built obstacles like
        medium to bigger jumps in form of doubles and drops can be not rollable!
      </td>
    </tr>
  </tbody>
    </table>
    <br />
    <p>{`The communication to the guest is key. No rating system in the world will
achieve a 100% congruence with all bikers. The difficulty rating will always
be a fuzzy parameter to make decisions. When a guest comes to a new area,
a “calibration tour” is suggested. This tour should be below the technical
(and physical) limit of the guest.`}</p>
    <br />
Here is a comparison of different rating systems in mountain biking:
    <br /><br />
    <div className="inline-block text-sm shadow bg-gray-100 text-center">
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">Trailguide</div>
    <div className="w-24 bg-green-600 p-1">Green</div>
    <div className="w-px"></div>
    <div className="w-24 bg-blue-600 p-1">Blue</div>
    <div className="w-px"></div>
    <div className="w-24 bg-red-600 p-1">Red</div>
    <div className="w-px"></div>
    <div className="w-px bg-gray-900"></div>
    <div className="w-px bg-gray-900"></div>
    <div className="w-24 bg-gray-900 p-1">Black</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">Singletrail-Skala</div>
    <div className="w-24 bg-blue-600 p-1">S0</div>
    <div className="w-px"></div>
    <div className="w-24 bg-blue-600 p-1">S1</div>
    <div className="w-px"></div>
    <div className="w-18 bg-red-600 p-1">S2</div>
    <div className="w-px"></div>
    <div className="w-18 bg-gray-900 p-1">S3</div>
    <div className="w-px"></div>
    <div className="w-6 bg-gray-900 p-1">S4</div>
    <div className="w-px"></div>
    <div className="w-6 bg-gray-900 p-1">S5</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">IMBA</div>
    <div className="w-18 text-black p-1">Easiest</div>
    <div className="w-px"></div>
    <div className="w-18 bg-green-600 p-1">Easy</div>
    <div className="w-px"></div>
    <div className="w-18 bg-blue-600 p-1">More diff.</div>
    <div className="w-px"></div>
    <div className="w-18 bg-gray-900 p-1">Very diff.</div>
    <div className="w-px"></div>
    <div className="w-px bg-gray-900"></div>
    <div className="w-24 bg-gray-900 p-1">Extreme</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">VTOPO</div>
    <div className="w-12 bg-green-600 p-1">T1</div>
    <div className="w-px"></div>
    <div className="w-18 bg-blue-600 p-1">T2</div>
    <div className="w-px"></div>
    <div className="w-24 bg-orange-600 p-1">T3</div>
    <div className="w-px"></div>
    <div className="w-24 bg-red-600 p-1">T4</div>
    <div className="w-px"></div>
    <div className="w-12 bg-purple-600 p-1">T5</div>
    <div className="w-px"></div>
    <div className="w-6 bg-purple-600 p-1">T6</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">FFC</div>
    <div className="w-96 text-black p-1">Not possible to compare</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">VTTRack</div>
    <div className="w-24 bg-green-600 p-1">V1</div>
    <div className="w-px"></div>
    <div className="w-18 bg-green-600 p-1">V2</div>
    <div className="w-px"></div>
    <div className="w-18 bg-blue-600 p-1">V3</div>
    <div className="w-px"></div>
    <div className="w-18 bg-orange-600 p-1">V4</div>
    <div className="w-px"></div>
    <div className="w-12 bg-orange-600 p-1">V5</div>
    <div className="w-px"></div>
    <div className="w-6 bg-orange-600 p-1">V6</div>
  </div>
  <div className="flex bg-gray-200 text-white m-px">
    <div className="w-40 p-1 text-black">CAI</div>
    <div className="w-12 bg-yellow-600 p-1">TC</div>
    <div className="w-px"></div>
    <div className="w-12 bg-green-600 p-1">MC</div>
    <div className="w-px"></div>
    <div className="w-24 bg-orange-600 p-1">BC</div>
    <div className="w-px"></div>
    <div className="w-18 bg-red-600 p-1">OC</div>
    <div className="w-px"></div>
    <div className="w-px bg-purple-600"></div>
    <div className="w-30 bg-purple-600 p-1">EC</div>
  </div>
    </div>
    <br /><br /><br />
    <h4>{`4) Local services`}</h4>
    <p>{`One product of Trailguide is the promotion of local services. They are
visible on the map and in all activity descriptions in a radius of 20km
around their location. If you want to promote local services in at your
destination, please contact us.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/pro/gpstrack" mdxType="Link">
  ← GPS track file (.gpx)
    </Link>
    <Link className="float-right" to="/pro/touraccess" mdxType="Link">
  Tours and access →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      